import { useEffect, useState } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { BtnPrimary } from '../btn-primary';
import { BtnSecondary } from '../btn-secondary';
import { SubheaderSelect } from '../subheader-select';
import { ColumnsSvg } from '../svg/columns-svg';
import type { HeaderItem } from '../data-table';
import {
  ColumnsContainer,
  EditColumnsFooter,
  EditColumnsHeader,
  EditColumnsStyled,
  SubTitle,
  Title
} from './style';
import { DragItem } from './drag-item';

interface EditColumnsProps<T> {
  initialHeaders: HeaderItem<T>[];
  columns: HeaderItem<T>[] | null;
  onSubmit: (columns: HeaderItem<T>[]) => void;
  onReset: () => void;
}

export const EditColumns = <T,>({
  initialHeaders,
  onSubmit,
  columns,
  onReset
}: EditColumnsProps<T>) => {
  const [show, setShow] = useState(false);
  const [headers, setHeaders] = useState(initialHeaders);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (active.id !== over?.id) {
      setHeaders((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleSubmit = () => {
    onSubmit(headers);
    setShow(false);
  };

  const handleReset = () => {
    onReset();
    setShow(false);
    setHeaders(initialHeaders);
  };

  useEffect(() => {
    if (columns) {
      setHeaders(columns);
    }
  }, [columns]);

  return (
    <SubheaderSelect
      title="Редагувати колонки"
      icon={ColumnsSvg}
      show={show}
      setShow={setShow}
      isActive={show || !!columns}
    >
      <EditColumnsStyled>
        <EditColumnsHeader>
          <Title>Редагувати колонки</Title>
          <SubTitle>
            Будь ласка, перетягніть і відпустіть потрібну колонку, щоб змінити
            порядок таблиці для Вашої зручності.
          </SubTitle>
        </EditColumnsHeader>
        <ColumnsContainer>
          <DragItem
            id="id"
            label="ID"
            checked={!!headers.find((item) => item.id === 'id')?.checked}
            setChecked={(checked) =>
              setHeaders((state) =>
                state.map((item) =>
                  item.id === 'id' ? { ...item, checked } : item
                )
              )
            }
          ></DragItem>
          <DndContext
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
            sensors={sensors}
            collisionDetection={closestCenter}
          >
            <SortableContext
              items={headers.map((item) => item.id as string)}
              strategy={verticalListSortingStrategy}
            >
              {headers
                .filter((item) => item.id !== 'id')
                .map((item, i) => (
                  <DragItem
                    id={`${item.id as string}`}
                    label={item.title}
                    checked={!!item?.checked}
                    setChecked={(checked) =>
                      setHeaders((state) =>
                        state.map((header) =>
                          header.id === item.id ? { ...item, checked } : header
                        )
                      )
                    }
                    key={i}
                  ></DragItem>
                ))}
            </SortableContext>
          </DndContext>
        </ColumnsContainer>
        <EditColumnsFooter>
          <BtnSecondary onClick={handleReset}>Скинути</BtnSecondary>
          <BtnPrimary onClick={handleSubmit}>Зберегти</BtnPrimary>
        </EditColumnsFooter>
      </EditColumnsStyled>
    </SubheaderSelect>
  );
};
