import type { ChangeEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IconContainer } from '../icon-container';
import { AngleSvg } from '../svg/angle-svg';
import { ExportSvg } from '../svg/export-svg';
import { ImportSvg } from '../svg/import-svg';
import { useOutsideClick } from '../../hooks/use-outside-click';
import { SettingsSvg } from '../svg/settings-svg';
import { useNotification } from '../../hooks/use-notification';
import {
  useExportContactsMutation,
  useImportContactMutation
} from '../../api/contact-api';
import { getApiError } from '../../helpers/get-api-error';
import { Popup } from '../popup';
import { ImportBigSvg } from '../svg/import-big-svg';
import { BtnSecondary } from '../btn-secondary';
import { BtnPrimary } from '../btn-primary';
import { LoaderSmall } from '../loader-small';
import {
  ExportImportHeader,
  ExportImportStyled,
  Icon,
  ImportName,
  OptionItem,
  Options,
  PopupBtns
} from './style';

interface ExportImportProps {
  canExport: boolean;
  canImport: boolean;
}

export const ExportImport = ({ canExport, canImport }: ExportImportProps) => {
  const [searchParams] = useSearchParams();
  const notification = useNotification();
  const [importContact, { isLoading }] = useImportContactMutation();
  const [exportContact] = useExportContactsMutation();
  const ref = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState(false);
  const [showConfitm, setShowConfirm] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [importError, setImportError] = useState(false);
  const [importErrorText, setImportErrorText] = useState<string | string[]>('');
  const [importErrorTitle, setImportErrorTitle] = useState('');
  useOutsideClick(ref, () => setShow(false));

  const handleChangeImport: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    setShowConfirm(true);
    if (files) {
      setFile(files[0]);
    }
  };

  const handleImport = () => {
    setImportErrorTitle('');
    setImportErrorText('');
    const formData = new FormData();
    if (file) {
      formData.append('importData', file);
      importContact(formData)
        .unwrap()
        .then((res) => {
          if (Object.keys(res.downloads_errors).length) {
            setImportError(true);
            setImportErrorTitle(res.message[0]);
            setImportErrorText(
              Object.entries(res.downloads_errors).map(
                ([key, value]) => `${key}: ${value}`
              )
            );

            return;
          }
          notification({
            type: 'success',
            title: 'База завантажена.',
            message: 'Ви успішно завантажили файл.'
          });
          setShowConfirm(false);
          setShow(false);
        })
        .catch((err) => {
          if (getApiError(err, true) === 'Internal Error') {
            notification({
              type: 'error',
              title: 'Помилка',
              message: getApiError(err)
            });
            setShowConfirm(false);
          } else {
            setImportError(true);
            setImportErrorText(getApiError(err, true));
            setShowConfirm(false);
          }
        })
        .finally(() => {
          setShow(false);
        });
    }
  };

  const handleExport = () => {
    setShow(false);
    exportContact(Object.fromEntries(searchParams.entries()))
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Контакти експортовано',
          message: ''
        });
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleCancel = () => {
    setFile(null);
    setShowConfirm(false);
    setShow(false);
  };

  useEffect(() => {
    if (!showConfitm && inputRef.current) {
      inputRef.current.value = '';
      setFile(null);
    }
  }, [showConfitm]);

  return (
    <ExportImportStyled ref={ref}>
      <ExportImportHeader onClick={() => setShow((state) => !state)}>
        <Icon>
          <SettingsSvg />
        </Icon>
        <AngleSvg />
      </ExportImportHeader>
      <Options show={show}>
        {canImport && (
          <OptionItem as="label">
            <input
              ref={inputRef}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleChangeImport}
              type="file"
            />
            <IconContainer m="0 10px 0 0">
              <ImportSvg />
            </IconContainer>
            Імпорт файла
          </OptionItem>
        )}
        {canExport && (
          <OptionItem onClick={handleExport}>
            <IconContainer m="0 10px 0 0">
              <ExportSvg />
            </IconContainer>
            Експорт файла
          </OptionItem>
        )}
      </Options>
      <Popup
        maxContentWidth="448px"
        show={showConfitm}
        setShow={setShowConfirm}
        title="Імпорт"
      >
        <ImportName>
          <ImportBigSvg />
          {file?.name}
        </ImportName>
        <PopupBtns>
          {!isLoading && (
            <BtnSecondary disabled={isLoading} onClick={handleCancel}>
              Відмінити
            </BtnSecondary>
          )}
          {isLoading ? (
            <LoaderSmall ac />
          ) : (
            <BtnPrimary disabled={isLoading} onClick={handleImport}>
              Зберегти
            </BtnPrimary>
          )}
        </PopupBtns>
      </Popup>
      <Popup
        title={importErrorTitle || 'При імпорті виникли помилки'}
        show={importError}
        setShow={setImportError}
      >
        {Array.isArray(importErrorText) ? (
          <ul style={{ padding: '20px 0' }}>
            {importErrorText.map((item, i) => (
              <li style={{ marginBottom: '10px', color: 'red' }} key={i}>
                {item}
              </li>
            ))}
          </ul>
        ) : (
          <p style={{ margin: '10px', color: 'red' }}>{importErrorText}</p>
        )}
      </Popup>
    </ExportImportStyled>
  );
};
