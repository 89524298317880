import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { useRef } from 'react';
import { IconContainer } from '../icon-container';
import { useOutsideClick } from '../../hooks/use-outside-click';
import { Options, SelectTitle, SubheaderSelectStyled } from './style';

interface SubheaderSelectProps {
  title: string;
  children: ReactNode;
  isActive?: boolean;
  icon: FC;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  showChildrenLeft?: boolean;
}

export const SubheaderSelect = ({
  title,
  children,
  isActive,
  icon: Icon,
  show,
  setShow,
  showChildrenLeft
}: SubheaderSelectProps) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => setShow(false));

  return (
    <SubheaderSelectStyled ref={ref}>
      <SelectTitle
        isActive={show || isActive}
        onClick={() => setShow((state) => !state)}
      >
        <IconContainer m="0 8px 0 0" mXl="0">
          <Icon />
        </IconContainer>
        <span>{title}</span>
      </SelectTitle>
      {show && (
        <Options $showChildrenLeft={showChildrenLeft}>{children}</Options>
      )}
    </SubheaderSelectStyled>
  );
};
