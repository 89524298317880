import { useCallback, useEffect, useState } from 'react';
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { useSearchParams } from 'react-router-dom';
import { KanbanCol } from '../../../../components/kanban-col';
import type { Step } from '../../../../models/event';
import {
  useChangeStepMutation,
  useEditContactMutation
} from '../../../../api/contact-api';
import { KanbanCard } from '../../../../components/kanban-card';
import type { Contact } from '../../../../models/contact';
import { useNotification } from '../../../../hooks/use-notification';
import { useTypedSelector } from '../../../../api/store';
import { KanbanStyled } from './style';

interface KanbanProps {
  stepsCount: number;
  steps: Step[];
  eventId: string | number;
}

export const Kanban = ({ stepsCount, steps, eventId }: KanbanProps) => {
  const notification = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const { phoneSearchTerm } = useTypedSelector((state) => state['page-data']);
  const [currentContact, setCurrentContact] = useState<Contact | null>(null);
  const [editContact] = useEditContactMutation();
  const [needRefetchCol, setNeedRefetchCol] = useState<
    undefined | string | number
  >(undefined);
  const [sizes, setSizes] = useState(
    steps.map((item) => ({ id: item.id, size: 25 }))
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const [changeStep, { isLoading: isChangingStep }] = useChangeStepMutation();

  const handleDragStart = (e: DragStartEvent) => {
    const draggingContact = e.active.data
      ? (e.active.data.current as Contact)
      : undefined;
    if (draggingContact) {
      setCurrentContact(draggingContact);
    }
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const draggingContact = e.active.data
      ? (e.active.data.current as Contact)
      : undefined;
    if (draggingContact && e.over?.id && draggingContact.current_steps) {
      const cardId = e.active.id;
      const prevColId = draggingContact.current_steps.find(
        (item) => item.event.id === eventId
      )?.id;
      const nextColId = e.over?.id;
      const prevSize = sizes.find((item) => item.id === prevColId)?.size;
      const nextSize = sizes.find((item) => item.id === nextColId)?.size;
      if (prevColId !== nextColId) {
        changeStep({
          contactId: cardId,
          stepId: nextColId,
          params: `?size=${nextSize}&events=${eventId}&current_steps=${nextColId}&sort_by=date&phone_search=${encodeURIComponent(
            phoneSearchTerm
          )}&${searchParams.toString()}`,
          prevParams: `?size=${prevSize}&events=${eventId}&current_steps=${
            draggingContact.current_steps.find(
              (item) => item.event.id === eventId
            )?.id
          }&sort_by=date&phone_search=${encodeURIComponent(
            phoneSearchTerm
          )}&${searchParams.toString()}`,
          contact: draggingContact,
          steps: draggingContact.current_steps,
          eventId
        })
          .unwrap()
          .then(() => {
            setNeedRefetchCol(prevColId);
          })
          .catch(() => {
            notification({
              type: 'error',
              title: 'Помилка',
              message: 'Не вдалося перемістити, спробуйте ще раз'
            });
          });
      }
    }
    setCurrentContact(null);
  };

  const handleSetProcessed = useCallback(
    (contact: Contact) => {
      editContact({
        id: contact.id,
        body: {
          current_steps: contact.current_steps?.map((item) =>
            item.event.id === eventId ? steps[steps.length - 1].id : item.id
          )
        }
      }).unwrap();
    },
    [editContact, eventId, steps]
  );

  const handleCancelProcessed = useCallback(
    (contact: Contact) => {
      editContact({
        id: contact.id,
        body: {
          current_steps: contact.current_steps?.map((item) =>
            item.event.id === eventId
              ? contact.previous_steps?.find(
                  (item) => item.event.id === eventId
                )?.id || steps[0].id
              : item.id
          )
        }
      }).unwrap();
    },
    [editContact, eventId, steps]
  );

  useEffect(() => {
    if (searchParams.has('current_steps') || searchParams.has('page')) {
      searchParams.delete('current_steps');
      searchParams.delete('page');
      setSearchParams(searchParams);
    }
    if (searchParams.has('page')) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return (
    <KanbanStyled colCount={stepsCount}>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        {steps.map((item, i) => (
          <KanbanCol
            setSizes={setSizes}
            key={item.id}
            title={item.name}
            idx={i}
            stepId={item.id}
            isProcessed={steps.length - 1 === i}
            needRefetchCol={needRefetchCol}
            setNeedRefetchCol={setNeedRefetchCol}
            isChangingStep={isChangingStep}
            onSetProcessed={handleSetProcessed}
            currentContactStep={
              currentContact?.current_steps?.find(
                (item) => item.event.id === eventId
              )?.id
            }
            onCancelProcessed={handleCancelProcessed}
          />
        ))}
        <DragOverlay>
          {currentContact?.id && (
            <KanbanCard
              onSetProcessed={handleSetProcessed}
              isProcessed={
                steps.length - 1 ===
                steps.findIndex(
                  (item) =>
                    item.id ===
                    currentContact?.current_steps?.find(
                      (item) => item.event.id === eventId
                    )?.id
                )
              }
              colIdx={steps.findIndex(
                (item) =>
                  item.id ===
                  currentContact?.current_steps?.find(
                    (item) => item.event.id === eventId
                  )?.id
              )}
              contact={currentContact}
              onCancelProcessed={handleCancelProcessed}
            />
          )}
        </DragOverlay>
      </DndContext>
    </KanbanStyled>
  );
};
