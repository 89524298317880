import type { Dispatch, SetStateAction, UIEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { useParams, useSearchParams } from 'react-router-dom';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { KanbanCard } from '../kanban-card';
import { useGetContactsQuery } from '../../api/contact-api';
import type { Contact } from '../../models/contact';
import { useTypedSelector } from '../../api/store';
import { StringCutter } from '../string-cutter';
import {
  ColHeader,
  ColStyled,
  DragPlaceholder,
  KanbanColStyled,
  LoaderStyled,
  TitleWrapper
} from './style';

const PAGE_SIZE = 25;

interface KanbanColProps {
  title: string;
  idx: number;
  stepId: string | number;
  setSizes: Dispatch<SetStateAction<{ id: string | number; size: number }[]>>;
  isProcessed: boolean;
  currentContactStep?: string | number;
  needRefetchCol: undefined | string | number;
  setNeedRefetchCol: Dispatch<SetStateAction<undefined | string | number>>;
  isChangingStep?: boolean;
  onSetProcessed: (contact: Contact) => void;
  onCancelProcessed: (contact: Contact) => void;
}

export const KanbanCol = ({
  title,
  idx,
  stepId,
  setSizes,
  isProcessed,
  currentContactStep,
  needRefetchCol,
  setNeedRefetchCol,
  isChangingStep,
  onSetProcessed,
  onCancelProcessed
}: KanbanColProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { eventId } = useParams();
  const { phoneSearchTerm } = useTypedSelector((state) => state['page-data']);
  const { scrollHeight, hasScrollBar, manuallySetScrollHeight } =
    useScrollHeight(ref);
  const [size, setSize] = useState(PAGE_SIZE);

  const [searchParams] = useSearchParams();

  const { setNodeRef, isOver, over } = useDroppable({
    id: stepId || ''
  });

  const {
    data: contacts,
    isFetching,
    refetch
  } = useGetContactsQuery(
    {
      params: `?size=${size}&events=${eventId}&current_steps=${stepId}&sort_by=date&phone_search=${encodeURIComponent(
        phoneSearchTerm
      )}&${searchParams.toString()}`
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const elem = e.target as HTMLElement;
    const scrollHeight = elem.scrollHeight;
    if (
      contacts &&
      Math.ceil(elem.scrollTop + elem.offsetHeight) === scrollHeight &&
      !isFetching &&
      contacts.results.length < contacts.count
    ) {
      setSize(() => {
        setSizes((sizes) =>
          sizes.map((item) =>
            item.id === stepId
              ? { ...item, size: contacts.results.length + PAGE_SIZE }
              : item
          )
        );

        return contacts.results.length + PAGE_SIZE;
      });
    }
  };

  useEffect(() => {
    if (
      contacts &&
      needRefetchCol &&
      needRefetchCol === stepId &&
      contacts?.results.length < contacts?.count
    ) {
      refetch()
        .unwrap()
        .finally(() => setNeedRefetchCol(undefined));
    }
  }, [needRefetchCol, refetch, setNeedRefetchCol, stepId, contacts]);

  useEffect(() => {
    manuallySetScrollHeight();
  }, [isOver, manuallySetScrollHeight]);

  return (
    <KanbanColStyled>
      <ColHeader isProcessed={isProcessed} idx={idx}>
        <TitleWrapper>
          <StringCutter lines="1">{title}</StringCutter>
        </TitleWrapper>
        <span> ({contacts?.count || 0})</span>
      </ColHeader>
      <ColStyled
        onScroll={handleScroll}
        ref={ref}
        style={{
          height: `${scrollHeight - 8}px`,
          paddingRight: hasScrollBar ? '4px' : '10px'
        }}
      >
        <div style={{ minHeight: '90%' }} ref={setNodeRef}>
          {isOver && currentContactStep !== over?.id && <DragPlaceholder />}
          {contacts?.results.map((item) => (
            <KanbanCard
              key={item.id}
              contact={item}
              colIdx={idx}
              isProcessed={isProcessed}
              isFetcing={isChangingStep || isFetching}
              onSetProcessed={onSetProcessed}
              onCancelProcessed={onCancelProcessed}
            />
          ))}
          {isFetching && <LoaderStyled />}
        </div>
      </ColStyled>
    </KanbanColStyled>
  );
};
