import type { ReactNode } from 'react';
import { IconContainerStyled } from './style';

interface IconContainerProps {
  children: ReactNode;
  m?: string;
  mXl?: string;
  onClick?: () => void;
}

export const IconContainer = ({
  children,
  m,
  mXl,
  onClick
}: IconContainerProps) => (
  <IconContainerStyled isActive={!!onClick} onClick={onClick} m={m} mXl={mXl}>
    {children}
  </IconContainerStyled>
);
